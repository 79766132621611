import React, { useState, useEffect } from 'react';
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client';
import {
    Container,
    Button,
    CircularProgress,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions
} from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import Axios from "axios";
import { getBaseURL } from '../baseURL';
import { useSelector } from "react-redux";
import  FileDownload  from 'js-file-download';
import Alert from '@mui/material/Alert';
import { toast, ToastContainer } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import CustomCircularProgress from './UiComponents/CustomCircularProgress'
import { PeoplePicker } from './PeoplePicker';

export const ViewTask = () => {
    const chunkSize = 10240000;
    const maxRetries = 8;

    const navigate = useNavigate();

    let { taskId } = useParams();

    const currentUser = useSelector(state => state.auth);

    const config = {
        headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken},
        responseType: 'blob'
      };

    // const DialogTitle = (props) => {
    //     const { children, onClose, ...other } = props;
    //         return (
    //             <MuiDialogTitle sx={{m:0,p:2}} {...other}>
    //             <Typography variant="h6">{children}</Typography>
    //             {onClose ? (
    //                 <IconButton aria-label="close" sx={{position: 'absolute',right: 8,top: 8,color: grey[500]}} onClick={onClose} size="large">
    //                 <CloseIcon />
    //                 </IconButton>
    //             ) : null}
    //             </MuiDialogTitle>
    //         );
    // };

    const GET_TASK = gql`
        query Tasks($taskId: Int!, $isListView: Boolean) {
            tasks(taskId: $taskId, isListView: $isListView) {
                id,
                approved,
                approvedType,
                comments,
                request {
                    id,
                    documentNumber,
                    dateCreated,
                    dateRequired,
                    description,
                    reviewType,
                    publicationName,
                    requestorUser {
                        email
                    }
                }
            }
        }
    `;

    const GET_REVIEW_TYPES = gql`
        query ReviewTypes{
            reviewType{
            id,
            name,
            description
            }
        }
	`;

    const GET_ATTACHMENTS = gql`
        query Attachments($id: Int, $requestId: Int, $taskId: Int) {
            attachments(id: $id, requestId: $requestId, taskId: $taskId) {
                id,
                fileName,
                taskId,
                dateUploaded,
                uploadedByUser {
                    firstName,
                    lastName
                },
                annotatedObjectID
            }
        }
    `;

    const UPDATE_TASK = gql`
        mutation UpdateTaskWithType($taskId: Int!, $comments: String!, $approved: Boolean!, $approvedType: Int!) {
            updateTaskWithType(taskId: $taskId, comments: $comments, approved: $approved, approvedType: $approvedType) {
                id
                approved
            }
        }
    `;

    const ADD_REVIEWER_TO_REQUEST = gql`
        mutation AddReviewerToRequest($id: Int!, $userGuid: String!) {
            addReviewerToRequest(id: $id, userGuid: $userGuid) {
                id
            }
        }
    `;

    const GET_REQUEST_TASKS = gql`
        query Requests($requestId: Int!, $viewingSingle: Boolean) {
            requests(requestId: $requestId, viewingSingle: $viewingSingle) {
                tasks {
                    assignedTo {
                        firstName,
                        lastName,
                        email
                    }
                }
            }
        }
    `;

    const [requestQuery, requestQueryResult] = useLazyQuery(GET_REQUEST_TASKS);

    const reviewTypeQuery = useQuery(GET_REVIEW_TYPES);

    const taskQuery = useQuery(GET_TASK, {variables: {taskId: parseInt(taskId), isListView: false, approved: null}});

    const [updateTask, updateTaskResult] = useMutation(UPDATE_TASK);

    const [attachmentQuery, attachmentQueryResult] = useLazyQuery(GET_ATTACHMENTS);

    const [addUserToRequest, addUserToRequestResult] = useMutation(ADD_REVIEWER_TO_REQUEST);

    const [requestId, setRequestId] = useState();
    const [fileUploader, setFileUploader] = useState();
    const [approved, setApproved] = useState(null);
    const [approvedType, setApprovedType] = useState(0);
    const [attachments, setAttachments] = useState([]);
    const [comments, setComments] = useState("");
    const [openValidationOverride, setOpenValidationOverride] = useState(false)
    const [valiationDialogAction, setValiationDialogAction] = useState({run:()=>{}})
    const [loadingAnnotationInfo, setLoadingAnnotationInfo] = useState(true);
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
    const [newReviewer, setNewReviewer] = useState();
    const [requestAddReviewer, setRequestAddReviewer] = useState();
    const [attachmentsLoading, setAttachmentsLoading] = useState(true);

    useEffect(() => {
        if(attachmentQueryResult && attachmentQueryResult.data && attachmentQueryResult.data.attachments) {
            // console.log("All Attachments: ", attachmentQueryResult.data.attachments)
            // console.log("Filtered Attachments: ", attachmentQueryResult.data.attachments.filter(a => a.taskId === null || a.taskId === parseInt(taskId)));
            // var filteredAttachments = attachmentQueryResult.data.attachments.filter(a => a.taskId === null || a.taskId === parseInt(taskId))
            // setAttachments(filteredAttachments);

            setAttachments([...attachmentQueryResult.data.attachments]);
            setAttachmentsLoading(false)
        }
    }, [attachmentQueryResult]);

    useEffect(() => {
        if(taskQuery.data && taskQuery.data.tasks[0]) {
            setRequestId(taskQuery.data.tasks[0].request.id);
            setApproved(taskQuery.data.tasks[0].approved);
            setComments(taskQuery.data.tasks[0].comments ?? "");
            setApprovedType(taskQuery.data.tasks[0].approvedType ? taskQuery.data.tasks[0].approvedType : 0);
        }
    }, [taskQuery]);

    useEffect(() => {
        if(requestId) {
            setAttachmentsLoading(true)
            attachmentQuery({variables: {requestId: parseInt(requestId)}});
            requestQuery({variables: {requestId: parseInt(requestId), viewingSingle: true}});
        }
    }, [requestId]);

    const downloadFile = (attachmentId, fileName, withAnnotations) => {
        var attachmentsCopy = [...attachments];

        //Set attachment to downloading so button can reflect that
        attachmentsCopy.find(a => a.id === attachmentId).loading = true;
        setAttachments(attachmentsCopy);

        Axios.get(`${getBaseURL()}/filedownload/${attachmentId}/${withAnnotations}`, config)
        .then((response) => {
            FileDownload(response.data, fileName);

            attachmentsCopy = [...attachments];
            attachmentsCopy.find(a => a.id === attachmentId).loading = false;
            setAttachments(attachmentsCopy);
        })
        .catch((error) => {
            attachmentsCopy = [...attachments];
            attachmentsCopy.find(a => a.id === attachmentId).loading = false;
            setAttachments(attachmentsCopy);
        });
    }

    const onFilesUploading = (args) => {
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.jwtIdToken.idToken}`);
    }
    const onFilesUploadSuccess = () => {
        attachmentQuery({variables: {requestId: parseInt(requestId)}});
    }

    const onFilesUploadError = (args) => {
        console.log('error loading file')
        console.log(args)
    }

    const validateCommentsOrMarkup = () => {

        // if (!comments || !comments.replace(/ /g,'')){
        //     console.log('no comments')
        // }

        if (comments && comments.replace(/ /g,'') !== ''){
            console.log('has comments')
            return true
        }

        //find successful upload, guaranteed to be user
        let fileUploaded = false;
        if (fileUploader.fileList.length > 0){
            for (let f of fileUploader.filesData) {
                if (f.statusCode == 2){
                    return true
                }
            }
        }

        try {
            //this is partially reliable due to differences processing of user first/last name
            if (!fileUploaded && attachments.length > 0){
                //attempt to match user name to up
                for (let f of attachments) {
                    const uploadUser = (f.uploadedByUser.firstName + f.uploadedByUser.lastName).replace(' ','').toLowerCase()
                    const _currentUser = currentUser.jwtIdToken.account.name.replace(' ','').toLowerCase()
                    if (uploadUser === _currentUser){
                        return true
                    }
                }
            }
        } catch (err){
            console.log('error checking username')
            console.log('err')
        }
    }

    const onApproveReject = async (approved, requireCommentsOrMarkup, overrideValidation = false) => {
        if (!overrideValidation && requireCommentsOrMarkup) {

            //validate
            if (!validateCommentsOrMarkup()){
                setValiationDialogAction({run: () => {onApproveReject(approved, requireCommentsOrMarkup, true)}})
                setOpenValidationOverride(true)
                return;
            }
        }

        const _approvedStyle = requireCommentsOrMarkup ? 2 : 1

        await updateTask({variables: {taskId: parseInt(taskId), comments: comments || '', approved: approved, approvedType: _approvedStyle}});
        taskQuery.refetch();
    }

    useEffect(() => {
        if(updateTaskResult && updateTaskResult.data && updateTaskResult.data.updateTask) {
            setApproved(updateTaskResult.data.updateTask.approved);
            setApprovedType(updateTaskResult.data.updateTask.approvedType ? updateTaskResult.data.updateTask.approvedType : 0)
        }
    }, [updateTaskResult]);

    const isReviewerFileDownloading = (fileId) => {
        try{
            if(attachments.find(a => a.id === fileId).loading) {
                return true;
            }
            else {
                return false;
            }
        }
        catch {
            return false;
        }
    }

    const onSaveClick = () => {
        updateTask({variables: {taskId: parseInt(taskId), comments: comments}})
        .then(() => {
            toast.success(`Task Updated Successfully`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            taskQuery.refetch();
        })
        .catch(() => {
            toast.error(`A problem occurred updating task`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        });
    }

    const onAddReviewerToRequest = (user) => {
        addUserToRequest({variables: {id: requestId, userGuid: user}})
        .then(() => {
            toast.success(`Added User Successfully`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            requestQueryResult.refetch();

            setAddUserDialogOpen(false);
        })
        .catch(() => {
            toast.error(`A problem occurred adding user.`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            setAddUserDialogOpen(false);
        });
    }

    const handleDialogClose = () => {
        setAddUserDialogOpen(false);
    }

    const handleValidationDialogClose = () => {
        setOpenValidationOverride(false);
    }

    const onChunkUploading = (args) => {
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.jwtIdToken.idToken}`);
    }

    return(
        <Container>
            <Typography variant="h5">View Task</Typography>

            {(taskQuery.data && reviewTypeQuery.data && taskQuery.data.tasks[0] && reviewTypeQuery.data.reviewType) ?
                <React.Fragment>
                    <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}}>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h6">Review Type:&nbsp;</Typography>
                            </Grid>
                            <Grid item style={{paddingTop: '3px'}}>
                                <Typography variant="subtitle1">{reviewTypeQuery.data.reviewType.find(t => t.id == taskQuery.data.tasks[0].request.reviewType).name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h6">Publication Name:&nbsp;</Typography>
                            </Grid>
                            <Grid item style={{paddingTop: '3px'}}>
                                <Typography variant="subtitle1">{taskQuery.data.tasks[0].request.publicationName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h6">Document Number:&nbsp;</Typography>
                            </Grid>
                            <Grid item style={{paddingTop: '3px'}}>
                                <Typography variant="subtitle1">{taskQuery.data.tasks[0].request.documentNumber}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h6">Originator:&nbsp;</Typography>
                            </Grid>
                            <Grid item style={{paddingTop: '3px'}}>
                                <Typography variant="subtitle1">{taskQuery.data.tasks[0].request.requestorUser.email}</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="h6">Description:</Typography>
                        <div>
                            { taskQuery.data.tasks[0].request.description }
                        </div>
                    </Paper>
                    <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}} style={{marginTop: '10px'}}>
                        <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" style={{marginTop: '10px'}}>
                            <Typography variant="h5" style={{flex: 1}}>Reviewers</Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    setAddUserDialogOpen(true);
                                }}
                            >Add Reviewer</Button>
                        </Grid>
                        {requestQueryResult && requestQueryResult.data && requestQueryResult.data.requests && requestQueryResult.data.requests[0] && requestQueryResult.data.requests[0].tasks &&
                            requestQueryResult.data.requests[0].tasks.map((item, index) => {
                                return(
                                    <Typography key={`${index}_${item.assignedTo.lastName}`} variant="subtitle1">{item.assignedTo.firstName} {item.assignedTo.lastName}</Typography>
                                );
                            })
                        }
                    </Paper>
                    <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}} style={{marginTop: '10px'}}>
                        <Typography variant="h6">Request Files</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Uploaded By</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {attachmentsLoading ? (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <div style={{display: 'flex', justifyContent: 'flex-start', gap: '1em',}}>
                                            <div style={{position: 'relative', width: '2em', height: '2em'}}><CustomCircularProgress/></div>
                                            <span>Loading Attachments ... </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )  : attachments.length > 0 ? attachments.map((item, index) => {
                                    return(
                                        <TableRow key={index}>
                                            <TableCell>{item.fileName}</TableCell>
                                            <TableCell>
                                                {(item.uploadedByUser && item.uploadedByUser.firstName && item.uploadedByUser.lastName) ?
                                                    `${item.uploadedByUser.firstName} ${item.uploadedByUser.lastName}`
                                                :
                                                    ""
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.dateUploaded ?
                                                    new Date(item.dateUploaded).toLocaleDateString()
                                                :
                                                    ""
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={() => { navigate(`/edit/${requestId}/${taskId}/${item.id}/${item.fileName}`)}}
                                                        disabled={isReviewerFileDownloading(item.id)}
                                                        style={{display: item.fileName.toLowerCase().endsWith(".pdf") && new Date(taskQuery.data.tasks[0].request.dateRequired) >= new Date(new Date().setHours(0,0,0,0)) ? "relative": "none", marginBottom: "10px"}}
                                                        >
                                                        {isReviewerFileDownloading(item.id) && <CustomCircularProgress />}
                                                        Annotate
                                                </Button>
                                                <br style={{display: item.fileName.toLowerCase().endsWith(".pdf") && new Date(taskQuery.data.tasks[0].request.dateRequired) >= new Date(new Date().setHours(0,0,0,0)) ? "relative": "none"}}/>
                                                <Button
                                                    variant="contained"
                                                    color={new Date(taskQuery.data.tasks[0].request.dateRequired) >= new Date(new Date().setHours(0,0,0,0)) ? "secondary" : "primary"}
                                                    onClick={() => {downloadFile(item.id, item.fileName, false)}}
                                                    disabled={isReviewerFileDownloading(item.id)}
                                                >
                                                    {isReviewerFileDownloading(item.id) && <CustomCircularProgress />}
                                                    Download
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color={new Date(taskQuery.data.tasks[0].request.dateRequired) >= new Date(new Date().setHours(0,0,0,0)) ? "secondary" : "primary"}
                                                    onClick={() => {downloadFile(item.id, item.fileName, true)}}
                                                    disabled={isReviewerFileDownloading(item.id) || item.annotatedObjectID === null}
                                                    style={{display: item.annotatedObjectID !== null ? "relative": "none", marginLeft: "10px"}}
                                                >
                                                    {isReviewerFileDownloading(item.id) && <CustomCircularProgress />}
                                                    Download Annotated
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) : (
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            No Attachments
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            </TableBody>
                        </Table>
                    </Paper>
                    <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}} style={{marginTop: '10px'}}>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <Typography variant="h6">Add Files and Attachments</Typography>
                            <span>(*max file upload size is 60MB.)</span>
                        </div>
                        <UploaderComponent 
                            autoUpload={false}
                            maxFileSize={62914560}//60MB
                            type = 'file'
                            ref={fileUpload => setFileUploader(fileUpload)}
                            chunkUploading={onFilesUploading}
                            style={{backgroundColor: "blue"}}
                            asyncSettings = {{chunkSize: chunkSize, retryCount: maxRetries, saveUrl: `${getBaseURL()}/fileupload/${requestId}/${taskId}`}}
                            uploading={onFilesUploading}
                            success={onFilesUploadSuccess}
                            failure={onFilesUploadError}
                            id='fileUpload'
                        />
                        <Typography style={{marginTop: '10px'}} variant="subtitle1">Files are uploaded when "File uploaded successfully" is shown after clicking upload. 100% means the files are still uploading.</Typography>

                        <TextField
                            color="primary"
                            variant="outlined"
                            label="Comments"
                            fullWidth
                            multiline
                            minRows={4}
                            value={comments || ''}
                            onChange={(e) => {setComments(e.target.value);}}
                        />
                        {/* {taskQuery.data && taskQuery.data.tasks[0].approved ?
                            <Alert severity="success">Task Approved</Alert>
                        :
                            taskQuery.data.tasks[0].approved === false &&
                            <Alert severity="success">Task Rejected</Alert>
                        } */}

                        {(taskQuery.data.tasks[0].approved === null && updateTaskResult) &&
                            updateTaskResult.error ?
                                <Alert severity="error">An Error Occurred</Alert>
                            :
                            approved === null ? 
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{margin: '10px'}}
                                        onClick={() => {onApproveReject(true, false)}}
                                        disabled={updateTaskResult.called}
                                    >
                                        {updateTaskResult.called && <CustomCircularProgress />}
                                        Approved, no further changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{margin: '10px'}}
                                        onClick={() => {onApproveReject(true, true)}}
                                        disabled={updateTaskResult.called}
                                    >
                                        {updateTaskResult.called && <CustomCircularProgress />}
                                        Approved, see comments or attached markup
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{margin: '10px'}}
                                        onClick={() => {onApproveReject(false, true)}}
                                        disabled={updateTaskResult.called}
                                    >
                                        {updateTaskResult.called && <CustomCircularProgress />}
                                        Rejected, see comments or attached markup
                                    </Button>
                                </React.Fragment> 
                            :
                            approved ? 
                                <Alert severity="success">Task Approved
                                {approvedType === 1 ? ', no further changes' : 
                                approvedType === 2 ? ', see comments or attached markup' : 
                                approvedType === 3 ? ', no response' : ''}</Alert>
                            :
                                <Alert severity="success">Task Rejected, see comments or attached markup</Alert>
                        }
                        <Button
                            color="primary"
                            onClick={() => {onSaveClick();}}
                            disabled={updateTaskResult.loading}
                        >
                            Save
                        </Button>
                    </Paper>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                    />
                     <Dialog open={addUserDialogOpen} fullWidth maxWidth='md' onClose={handleDialogClose}>
                        <DialogTitle sx={{m:0,p:2}}>
                            <Typography variant="h6">Add Reviewer</Typography>
                            {handleDialogClose ? (
                                <IconButton aria-label="close" sx={{position: 'absolute',right: 8,top: 8,color: grey[500]}} onClick={handleDialogClose} size="large">
                                <CloseIcon />
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                        <Container sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}}>
                        <PeoplePicker
                            _helperText=""
                            _peopleValue={newReviewer}
                            _setPeopleValue={setNewReviewer}
                            _label="User"
                            _isMultiple={false}
                            _required={false}
                            _internalUsers={false}
                            style={{padding: '10px'}}
                        />
                        </Container>
                        <DialogActions sx={{m:0,p:1}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {onAddReviewerToRequest(newReviewer.guid);}}
                                disabled={addUserToRequestResult.loading}
                            >
                                {addUserToRequestResult.loading && <CustomCircularProgress />}
                                Add Reviewer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openValidationOverride} fullWidth maxWidth='md' onClose={handleValidationDialogClose}>
                        <DialogTitle sx={{m:0,p:2}}>
                            <Typography variant="h6">Comments or Markup Required</Typography>
                            {handleValidationDialogClose ? (
                                <IconButton aria-label="close" sx={{position: 'absolute',right: 8,top: 8,color: grey[500]}} onClick={handleValidationDialogClose} size="large">
                                <CloseIcon />
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                        <Container sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}}>
                            <Alert severity="warning">That action requires that comments be entered or a file with markup attached.</Alert>
                            <Typography style={{marginTop: '2em', paddingLeft:'2em'}} variant="subtitle1">
                                If uploading a file, please ensure you clicked 'Upload'
                            </Typography>
                        </Container>
                        <DialogActions sx={{m:0,p:1}} style={{margin: '1em', padding: '1em'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleValidationDialogClose()
                                    valiationDialogAction.run()
                                }}
                            >
                                Continue, File Already Uploaded
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setValiationDialogAction({run: () => {}})
                                    handleValidationDialogClose()
                                }}
                            >
                                Ok, I will add comments or markup
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            :
                <CircularProgress color="primary"/>
            }
        </Container>
    );
}