import React, { useState, useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
//import { ApolloServerErrorCode } from '@apollo/server/errors';
import { Container, Button, CircularProgress, Typography, Paper, Checkbox, FormControl, Select, MenuItem, Grid, InputLabel, FormControlLabel, Box} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TablePaginationActions } from '../actions/TablePaginationActions';
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsGridOverlay } from './NoRowsGridOverlay';
import { useSelector } from "react-redux";
import ListViewSelector from '../components/UiComponents/ListViewSelector'


export const Tasks = () => {
    const currentUser = useSelector(state => state.auth);
    let navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === '/'){
            //presumably redirect location on initial load
            const downloadpath = localStorage.getItem('download_redirectUri');
            if (downloadpath) {
                console.log('redirect to download path')
                localStorage.setItem('download_redirectUri', '')
                navigate(downloadpath)
            }
        }
        //localStorage.setItem('download_redirectUri', window.location.href.replace(window.location.origin,""))
    }, [])

    const columns = [
        { 
            field: 'request.documentNumber', 
            headerName: 'Document Number', 
            flex: 0.5, 
            valueGetter: (params) => {return params.row.request.documentNumber}
        },
        { 
            field: 'request.publicationName', 
            headerName: 'Publication Name', 
            flex: 0.5, 
            valueGetter: (params) => {return params.row.request.publicationName}
        },
        { 
            field: 'request.dateCreated',
            headerName: 'Date Created',
            flex: 0.3,
            valueGetter: (params) => {
                var dateCreatedDate = new Date(params.row.request.dateCreated);
                return dateCreatedDate.toLocaleDateString();
            }
        },
        { 
            field: 'request.dateRequired',
            headerName: 'Date Required',
            flex: 0.3,
            valueGetter: (params) => {
                var dateDueDate = new Date(params.row.request.dateRequired);
                return dateDueDate.toLocaleDateString();
            }
        }
    ];

    if (currentUser && (currentUser.user.userAttributes.findIndex(x => x.value && (x.attribute.attributeName === 'Manager' || x.attribute.attributeName === 'Admin Users')) >= 0)) {
        columns.push({ 
            field: 'request.requestorUser.lastName',
            headerName: 'Requestor',
            flex: 0.3,
            valueGetter: (params) => {
                return `${params.row.request.requestorUser.firstName} ${params.row.request.requestorUser.lastName}`;
            }
        });
        columns.push({ 
            field: 'assignedTo.lastName',
            headerName: 'Assigned To',
            flex: 0.3,
            valueGetter: (params) => {
                return `${params.row.assignedTo.firstName} ${params.row.assignedTo.lastName}`;
            }
        });
    }
    columns.push({ 
        field: 'id', 
        headerName: ' ', 
        sortable: false, 
        filterable: false, 
        disableColumnMenu: true, 
        renderCell: (params) => (
            <Button 
                variant="contained"
                color="primary"
                onClick={() => navigate("/viewtask/" + params.value)}
            >
                View
            </Button>
    )});


    const [sortModel, setSortModel] = useState([
        {
            field: 'request.dateRequired',
            sort: 'desc'
        }
    ]);

    const GET_TASKS = gql`
        query Tasks ($isActive: Boolean, $isListView: Boolean, $taskStatus: String!, $futureRequestsOnly: Boolean, $archived: Boolean, $displaySetting: String) {
            tasks(isActive: $isActive, isListView: $isListView, taskStatus: $taskStatus, futureRequestsOnly: $futureRequestsOnly, archived: $archived, displaySetting: $displaySetting) {
                id,
                request {
                    documentNumber,
                    publicationName,
                    dateCreated,
                    dateRequired,
                    requestorUser {
                        firstName,
                        lastName
                    }
                },
                assignedTo {
                    firstName,
                    lastName
                }
            }
        }
    `;

    const [taskQuery, taskQueryResult] = useLazyQuery(GET_TASKS);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0,
      });

    const[rowsPerPage, setRowsPerPage] = useState(5);
    const[tasks, setTasks] = useState([]);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tasks.length - paginationModel.page * rowsPerPage);
    const [onlyFutureRequests, setOnlyFutureRequests] = useState(true);
    const [displaySetting,setDisplaySetting] = useState("user");//options: user, team, team+, all
    const [taskStatus, setTaskStatus] = useState("incomplete");

    const handleSortChange = (model) => {
        /* if statement to prevent the infinite loop by confirming model is 
         different than the current sortModel state */
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
        }
    };

    useEffect(() => {
        //console.log('trigger taskQueryResult')
        //console.log(taskQueryResult)
        if(taskQueryResult.data && taskQueryResult.data.tasks) {
            //console.log('should see task results')
            //console.log(taskQueryResult.data.tasks)
            setTasks(taskQueryResult.data.tasks);
        }
    }, [taskQueryResult]);

    useEffect(() => {
        try{
            taskQuery({variables: {isActive: true, isListView: true, taskStatus: taskStatus, futureRequestsOnly: onlyFutureRequests, archived: false, displaySetting: displaySetting}});
        } catch (e){
            console.log('error getting tasks')
            console.log(e)
        }
    }, [taskStatus, onlyFutureRequests, displaySetting])

    return(
        <Container maxWidth={false} disableGutters={true}>
            {/* <Stack sx={theme => ({minHeight:`calc(100vh - ${theme.spacing(13.5)})`})} direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
            </Stack> */}
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <Typography variant="h5" style={{flex: 1}}>Tasks</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={onlyFutureRequests}
                            onChange={(e) => {setOnlyFutureRequests(e.target.checked);}}
                            color="primary"
                            disabled={taskQueryResult.loading}
                        />
                    }
                    label="Only Future Due Dates"
                    style={{marginTop: '15px'}}
                />
                <FormControl variant='standard'>
                    <InputLabel>Task Status</InputLabel>
                    <Select
                        color="primary"
                        value={taskStatus}
                        onChange={(e) => {
                            setTaskStatus(e.target.value);
                        }}
                        disabled={taskQueryResult.loading}
                    >
                        <MenuItem value={"incomplete"}>Incomplete</MenuItem>
                        <MenuItem value={"complete"}>Complete</MenuItem>
                        <MenuItem value={"all"}>All</MenuItem>
                    </Select>
                </FormControl>
                <ListViewSelector
                    currentUser={currentUser}
                    displaySetting={displaySetting} 
                    setDisplaySetting={setDisplaySetting}
                    loading={taskQueryResult.loading}
                />
            </Grid>

            {taskQueryResult.loading ?
                <CircularProgress color="primary"/>
            :
                tasks &&
                    <Paper>
                        <Box sx={{ height: 1000, width: '100%' }}>
                            <DataGrid
                                rows={tasks} 
                                columns={columns}
                                slots={{
                                    noRowsOverlay: NoRowsGridOverlay,
                                }}
                                pageSizeOptions={[25, 50, 100, 250, 500]}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                sortModel={sortModel}
                                onSortModelChange={(model) => handleSortChange(model)}
                            />
                        </Box>
                    </Paper>
            }
        </Container>
    );
}