import { unstable_useBlocker as useBlocker } from 'react-router-dom'

/**
 * temporary: replacement for missing component Prompt in react-router-dom v6
 * TODO: When react-router-dom v6 reintroduces Prompt, this temp component can be deleted.
 * 
 * src: https://stackoverflow.com/questions/70178993/alternative-for-prompt-in-react-router-v6
 */
function Prompt(props) {
    const block = props.when
    const onExitFunction = props.onExit

    useBlocker(() => {
      console.log("Prompt - useBlocker");
      console.log("Prompt - block status: " + block);
      if (block) {
        const shouldStay = ! window.confirm(props.message)
        if (!shouldStay) {
          if (onExitFunction) {
            onExitFunction();
          }
        }
        return shouldStay
      }
      if (onExitFunction) {
        onExitFunction();
      }
      return false
    })

  return (
    <div key={block}/>
  )
}

export default Prompt